import $ from 'jquery'

function init() {
  var $cell = $('.xgrid_Item');
  
  // Remove previous handlers to prevent duplicates
  $cell.find('.xgrid_Tile').off('click');
  $cell.find('.xgrid_Close').off('click');

  $cell.find('.xgrid_Tile').click(function() {
    var $thisCell = $(this).closest('.xgrid_Item');
    if ($thisCell.hasClass('is-collapsed')) {
      $cell.not($thisCell).removeClass('is-expanded').addClass('is-collapsed');
      $thisCell.removeClass('is-collapsed').addClass('is-expanded');
      $('html,body').animate({
        scrollTop: $(this).offset().top
      } , 'fast')
    } else {
      $thisCell.removeClass('is-expanded').addClass('is-collapsed');
    }
  });
  $cell.find('.xgrid_Close').click(function(){
    var $thisCell = $(this).closest('.xgrid_Item');
    $thisCell.removeClass('is-expanded').addClass('is-collapsed');
  });
}

export default function initXGrid(){
  $(document).ready( init )
  let resizeTimeout;
  $(window).resize(function() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(function() {
      init();
    }, 200);
  });
}
