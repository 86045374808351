import $ from 'jquery'

function init() {

  applyPositionToPortfolioItems();
  cat_ajax_get('portfolio_news');

  // init Isotope
  var $grid = $('.portfolioItems').isotope({
    itemSelector: '.portfolioItem',
    percentPosition: true,
    layoutMode: 'masonry',
    masonry: {
      columnWidth: '.grid-sizer',
      gutterWidth: 0
    }
  });

  //change is-expanded class on clicked item
  // $grid.on( 'click', '.open-portfolio', function() {   
  //   const item = $(this).parent()
  //   if (! item.is( "is-expanded" ) ) {
  //     $('.portfolioItem').removeClass('is-expanded');
  //     item.toggleClass('is-expanded');
  //   }
  //   $grid.isotope('layout');
  //   // the following gen of code scrolls the hash of the url, after isotope layout finishes
  //   setTimeout(function() { 
  //     $('html,body').animate({
  //       scrollTop: - $("#header").height() - $("#categoryMenu").height() + $( '.portfolioItem.is-expanded' ).offset().top
  //     } , 'slow')
  //   }, 800);
  // });

  // $('.close-portfolio').on( 'click', function() {     
  //   const parentItem = $( this ).parent().parent().parent()
  //   //if (! $( this ).parent().parent().parent().is( "is-expanded" ) ) {
  //     parentItem.removeClass('is-expanded');
  //   //  $( this ).parent().parent().parent().toggleClass('is-expanded');
  //   //}
  //   $grid.isotope('layout');
  // });

  // filter functions
  var filterFns = {
    // show if number is greater than 50
    numberGreaterThan50: function() {
      var number = $(this).find('.number').text();
      return parseInt( number, 10 ) > 50;
    },
    // show if name ends with -ium
    ium: function() {
      var name = $(this).find('.name').text();
      return name.match( /ium$/ );
    }
  };

  // bind filter button click
  $('.filters-button-group').on( 'click', 'a', function() {

    if($('.catMenuTitle').hasClass('is-active')) {
      $('.catMenuTitle').trigger('click');
    }

    var whatid = $(this).attr('data-catid');
    //alert(whatid);
    cat_ajax_get(whatid); //important here!!!
    $('.portfolioItem').removeClass('is-expanded');
    $('html,body').animate({ scrollTop: - $("#header").height() - $("#categoryMenu").height() + $("#portfolioItems").offset().top } , 'slow');
    var filterValue = $( this ).attr('data-filter');
    // use filterFn if matches value
    filterValue = filterFns[ filterValue ] || filterValue;
    $grid.isotope({ filter: filterValue });
    $grid.isotope( 'on', 'layoutComplete', applyPositionToPortfolioItems ); 

  });

  function applyPositionToPortfolioItems() {
    $('.pBio').removeAttr('style');
    $('.portfolioItem').each(function() {
      const bioElement = $(this).find('.pBio')
      const elementOffset = bioElement.offset().left - $(window).scrollLeft();
      bioElement.css('left', `-${elementOffset}px`);
    });
  }

  // change is-checked class on buttons
  $('.button-group').each( function( i, buttonGroup ) {
    var $buttonGroup = $( buttonGroup );
    $buttonGroup.on( 'click', 'a', function() {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      $( this ).addClass('is-checked');
    });
  });
      
  function cat_ajax_get(catID) {
    jQuery("a.ajax").removeClass("current");
    jQuery("a.ajax").addClass("current"); //adds class current to the category menu item being displayed so you can style it with css
    jQuery("#loading-animation").show();
    jQuery.ajax({
      type: 'POST',
      url: php_vars.ajax_url,
      data: {"action": "load-filter", cat: catID },
      success: function(response) {
        if (response.trim() === "") {
          jQuery("#layer1.newsLayer").hide();
        } else {
          jQuery("#layer1.newsLayer").show();
          jQuery("#layer1 .newsStories").html(response);
        }
        jQuery("#loading-animation").hide();
        return false;
      }
    });
  }

  const hash = window.location.hash
  if(hash.length > 0) {
    $(hash).trigger('click')
    if($('.catMenuTitle').hasClass('is-active')) {
      $('.catMenuTitle').trigger('click');
    }
  }
    
}

export default function initPortfolioFunctions(){
  $(document).ready( init )
  $('.catMenuTitle').on( 'click', function() {
    $('.catGroupWrapper').slideToggle()
    $(this).toggleClass('is-active')
  });
  if ( $( 'body' ).first().hasClass( 'page-id-12' ) ) {
    let resizeTimeout;
    let width = $(window).width(); 
    $(window).on('resize', function() {
      if ($(window).width() == width) return;
      width = $(window).width();
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(function() {
        location.reload();
      }, 500);
    });
  }
}