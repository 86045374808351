const statsGridBlock = () => {
  const statsGridBlocks = document.querySelectorAll(".stats-grid");

  if (statsGridBlocks.length === 0) return;

  statsGridBlocks.forEach((quizBlock) => {
    const revealChartButton = quizBlock.querySelector(".js-reveal-chart");

    revealChartButton.addEventListener("click", () => {
      quizBlock.classList.toggle("is-revealed");
      const buttonText = revealChartButton.querySelector("span");
      if (quizBlock.classList.contains("is-revealed")) {
        buttonText.textContent = "hide data";
      } else {
        buttonText.textContent = "data visualization";
      }
    });
  });
};

export default statsGridBlock;
