import $ from 'jquery'

const $doc = $(document)

function showLogos() {
  if( $(this).next('.impact-objectives__logos').css('display') == 'none') {
     $(this).next('.impact-objectives__logos').css('display', 'flex');
     $(this).text("- hide companies");
  } else { 
    $(this).next('.impact-objectives__logos').css('display', 'none');
    $(this).text("+ explore companies");
  }
}

export default function initShowLogos(){
  $doc.on('click', '.js-show-logos', showLogos )
}
