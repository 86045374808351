import $ from 'jquery'

const $doc = $(document)
let responsiveSize

function checkViewport() {
  const viewportWidth = $(window).width()
  if (viewportWidth > 1120) {
    responsiveSize = '211px'
  } else {
    responsiveSize = '170px'
  }
}

function init() {
  let restart;
  window.onresize = function(){
    clearTimeout(restart);
    $('.cs-approach__sector__item__title').removeAttr("style")
    restart = setTimeout(restartFunctions, 50);
  };
}

function restartFunctions(){
  $('.cs-approach__sector__item.is-active').children().find('.js-hide-sector').trigger('click')
  document.querySelectorAll('.cs-approach__sector__modal').forEach(dialog => {
    if (dialog.open) {
      dialog.close();
    }
  })
}

function showSector() {
  const hideButton = $(this).parent().find('.js-hide-sector')

  if($(window).width() > 900) {
    $('.js-hide-sector').not(hideButton).each(function() {
      $(this).trigger('click')
    });
    const element = $(this)
    if($('.cs-approach__sector__item').hasClass('is-active')) {
      setTimeout(function() {
        openSectors(element)
      }, 0);
    } else {
      openSectors(element)
    }
  }
}

function openSectors(element) {
  const description = element.parent().next('.cs-approach__sector__item__description')
  const closeButton = element.parent().find('.cs-approach__sector__close')
  const descriptionWidth = description.outerWidth() + 160
  const descriptionHeight = description.outerHeight() + 120
  element.hide()
  element.parent().parent().addClass('is-active')
  element.parent().animate({
    width: descriptionWidth+'px',
    height: descriptionHeight+'px'
  }, {
    duration: 200,
    complete: function() {
      description.fadeIn( 100 )
      closeButton.show()
    }
  })
}

function hideSector() {
  checkViewport()
  const $this = $(this)
  const item = $(this).parent()
  const wrapper = $(this).parent().parent()
  const button = $(this).parent().find('.cs-approach__sector__open.button--desktop')
  
  $this.parent().next('.cs-approach__sector__item__description').fadeOut(10, function() {
    item.animate({
      width: responsiveSize,
      height: responsiveSize
    }, {
      duration: 190,
      complete: function() {
        wrapper.removeClass('is-active')
        button.show()
        $this.hide()
      }
    })
  })
}


export default function initShowSector(){
  $doc.on('click', '.js-show-sector', showSector )
  $doc.on('click', '.js-hide-sector', hideSector )
  $(document).ready( init )
  document.querySelectorAll('.js-show-modal').forEach(button => {
    button.addEventListener('click', () => {
      const dialogId = button.getAttribute('data-dialog-id');
      const dialog = document.getElementById(dialogId);
      if (dialog) {
        dialog.showModal();
      }
    });
  });
  document.querySelectorAll('.js-hide-modal').forEach(button => {
    button.addEventListener('click', () => {
        const dialog = button.closest('.cs-approach__sector__modal');
        if (dialog) {
          dialog.close();
        }
    });
});

}