import $ from "jquery";

function initSlider() {
  const sliderWrapper = $(".js-posts-slider");
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      dots: false,
      infinite: false,
      nextArrow:
        '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="38" height="90" viewBox="0 0 38 90" fill="none"><path d="M3 2L34.1494 46.0465L3 88" stroke="#006F51" stroke-width="5"/><svg></button>',
      prevArrow:
        '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="38" height="90" viewBox="0 0 38 90" fill="none"><path d="M35.0469 88L3.89752 43.9535L35.0469 2" stroke="#006F51" stroke-width="5"/></svg></button>',
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow:
              '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="45" viewBox="0 0 28 45" fill="none"><path d="M2 2L24 22.9989L2 43" stroke="#006F51" stroke-width="5"/></svg></button>',
            prevArrow:
              '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="45" viewBox="0 0 28 45" fill="none"><path d="M26 43L4 22.0011L26 2" stroke="#006F51" stroke-width="5"/></svg></button>',
          },
        },
      ],
    });
  }
}

export default function initPostsSlider() {
  $(document).ready(initSlider);
}
