const quizBlock = () => {
  const quizBlocks = document.querySelectorAll(".quiz");

  if (quizBlocks.length === 0) return;

  quizBlocks.forEach((quizBlock) => {
    const revealAnswerButton = quizBlock.querySelector(".js-reveal-answer");
    const choices = quizBlock.querySelectorAll(".js-choice");

    revealAnswerButton.addEventListener("click", () => {
      quizBlock.classList.add("is-revealed");
    });

    choices.forEach((choice) => {
      choice.addEventListener("click", () => {
        quizBlock.classList.add("is-revealed");
      });
    });
  });
};

export default quizBlock;
