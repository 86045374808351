const timelineBlock = () => {
  const timelineBlocks = document.querySelectorAll(".timeline");

  if (timelineBlocks.length === 0) return;

  timelineBlocks.forEach((timelineBlock) => {
    const prevButton =
      timelineBlock.parentElement.querySelector(".js-timeline-prev");
    const nextButton =
      timelineBlock.parentElement.querySelector(".js-timeline-next");
    const wrapper = timelineBlock.querySelector(".js-timeline-wrapper");

    nextButton.addEventListener("click", () => {
      const currentScroll = wrapper.scrollLeft;
      const itemWidth = wrapper.querySelector(".js-milestone").offsetWidth;
      const nextScroll = currentScroll + (itemWidth + 30);
      wrapper.scrollTo({
        left: nextScroll,
        behavior: "smooth",
      });
    });

    prevButton.addEventListener("click", () => {
      const currentScroll = wrapper.scrollLeft;
      const itemWidth = wrapper.querySelector(".js-milestone").offsetWidth;
      const prevScroll = currentScroll - (itemWidth + 30);
      wrapper.scrollTo({
        left: prevScroll,
        behavior: "smooth",
      });
    });
  });
};

export default timelineBlock;
