import 'slick-carousel';

import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initXGrid from '/lib/xgrid'
import initPortfolioFunctions from '/lib/portfolio'
import initSticky from '/lib/sticky'
import initA11yFunctions from '/lib/accessibility'
import initShowLogos from '/lib/show-logos'
import initPostsSlider from '/lib/posts-slider'
import initShowSector from '/lib/show-sector'
import initQuiz from '/lib/quiz'
import initTimeline from '/lib/timeline'
import initStatsGrid from '/lib/stats-grid'

initGreenSock()
//initHeadroom()
//initMobileNav()
initA11yFunctions()
initXGrid()
initPortfolioFunctions()
//initSticky()
initShowLogos()
initPostsSlider()
initShowSector()
initQuiz()
initTimeline()
initStatsGrid()


$(function () {
    $('.expander').simpleexpand();
});

$(document).ready( function() {
    if (window.location.hash) {
      let myhash = location.hash+'-bio-trigger';
      console.log("URL hash component exists: "+myhash);
      $(myhash).addClass('is-expanded').removeClass('is-collapsed');
    }
});

$(window).on('load resize', function () {
    $('.video_background').resizeToParent();
});

$(document).ready( function() {
    var $kpiExpander = $('#showAllKpis');
    $kpiExpander.click( function() {
        $('.kpiBeyond6').removeClass('kpiBeyond6');
        $('.kpiBeyond8').removeClass('kpiBeyond8');
        $( this ).css( "display", "none" );
    });
});

// basic sidescroll
// ref: https://stackoverflow.com/questions/28576636/mouse-click-and-drag-instead-of-horizontal-scroll-bar-to-view-full-content-of-c#answer-77104729
$(document).ready( function() {
const pointerScroll = (elem) => {

    const dragStart = (ev) => elem.setPointerCapture(ev.pointerId);
    const dragEnd = (ev) => elem.releasePointerCapture(ev.pointerId);
    const drag = (ev) => elem.hasPointerCapture(ev.pointerId) && (elem.scrollLeft -= ev.movementX);
    
    elem.addEventListener("pointerdown", dragStart);
    elem.addEventListener("pointerup", dragEnd);
    elem.addEventListener("pointermove", drag);
  };
  
  document.querySelectorAll(".js-timeline-wrapper").forEach(pointerScroll);
});